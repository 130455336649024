<template>
  <div class="bg-white">
    <div class="max-w-10xl mx-auto py-4 px-4 w-full sm:px-6 lg:pl-8 lg:py-4">
      <div class="space-y-12">
        <div class="md:max-w-xl lg:max-w-3xl xl:max-w-none">
          <h2 class="lg:text-5xl mt-3 font-extrabold tracking-tight text-4xl">
            2021 AI-Campus Team
          </h2>
          <p class="text-xl text-gray-500"></p>
        </div>
        <ul
          class="
            grid
            md:grid-cols-1 md:gap-x-6 md:gap-y-12 md:space-y-0
            lg:grid-cols-3 lg:gap-x-20 lg:gap-y-16
            justify-between
            pb-4
            gap-y-16
          "
        >
          <li v-for="person in people" :key="person.name">
            <div
              class="max-w-6xl mx-auto h-full flex flex-col-reverse md:flex-row"
            >
              <div class="h-full flex flex-col">
                <h2 class="text-3xl font-bold text-black mb-1">
                  {{ person.name }}
                </h2>
                <h3
                  v-if="person.role !== 'Project Coach'"
                  class="text-xl text-black mb-4 font-bold"
                >
                  {{ person.role }}
                </h3>
                <h3 class="text-xl text-black">{{ person.title }}</h3>
                <h3 class="text-xl text-black">{{ person.affiliation }}</h3>
                <div v-if="person.teams" class="mt-auto">
                  <h3 class="mt-6 md:mt-0 text-3xl text-black font-bold">
                    Project Coach:
                  </h3>
                  <p
                    class="text-xl"
                    v-for="team in person.teams"
                    :key="team.id"
                  >
                    {{ team }}
                  </p>
                </div>
              </div>
              <div
                v-if="person.imageUrl"
                class="md:ml-auto mb-3 md:mb-0 mx-auto md:mx-0 md:my-auto"
              >
                <img
                  class="h-48 w-48 object-cover shadow-lg rounded-lg"
                  :src="getImgUrl(person.imageUrl)"
                  alt=""
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
const people = [
  {
    name: "Xiuzhen Huang, Ph.D",
    title: "Professor",
    role: "Founding Director of Arkansas AI-Campus",
    affiliation: "Arkansas State University",
    imageUrl: "img/xhuang.jpg",
    websiteUrl:
      "https://www.astate.edu/college/ecs/people/people-details.dot?pid=95023da5-a256-4dd5-9e7d-b55ba68a2a9c",
  },
  {
    name: "Yuanfang Guan, Ph.D",
    role: "Chief Scientist",
    title: "Associate Professor",
    affiliation: "University of Michigan Medical School",
    imageUrl: "img/yguan.jpg",
    // websiteUrl: "https://em-bellis.github.io/",
  },
  {
    name: "Jason Causey, Ph.D",
    role: "AI-Campus Head Coach",
    imageUrl: "img/jcausey.jpg",
    affiliation: "Arkansas State University",
    title: "Assistant Professor",
    websiteUrl:
      "https://www.astate.edu/college/ecs/people/people-details.dot?pid=f7d41d57-2f7f-46bc-b7ef-a10e6f2a9c42",
    teams: ["Agricultural Imaging", "Business Analytics Demand Forecasting"],
  },
  {
    name: "Jake Qualls, Ph.D",
    role: "Project Coach",
    title: "Assistant Professor",
    affiliation: "Arkansas State University",
    imageUrl: "img/jqualls.jpg",
    websiteUrl:
      "https://www.astate.edu/college/ecs/people/people-details.dot?pid=225fda7f-9415-4bb3-b681-8582e67525e8",
    teams: ["NLP"],
  },
  {
    name: "Emily Bellis, Ph.D",
    role: "Director for Student Engagment & Project Coach",
    title: "Research Assistant Professor",
    affiliation: "Arkansas State University",
    imageUrl: "img/ebellis_small.jpg",
    websiteUrl: "https://em-bellis.github.io/",
    teams: ["Herbarium", "Maize Genomics"],
  },
  {
    name: "Jonathan Stubblefield, Ph.D",
    role: "Project Coach",
    title: "Assistant Professor",
    affiliation: "Arkansas State University",
    teams: ["Reinforcement Learning"],
    imageUrl: "img/jstubblefield.jpg",
    // websiteUrl:
    //   "https://www.astate.edu/college/conhp/departments/medical-imaging-radiation-sciences/faculty-staff/people-details.dot?pid=c3c780d3-4217-4fb8-8de7-ad82894aed6b",
  },
  {
    name: "Kaimen Zeng, Ph.D",
    role: "Project Coach",
    title: "Assistant Professor of Electrical Engineering",
    affiliation: "Arkansas Tech University",
    teams: ["Fire Detection", "Self-Driving Car"],
    imageUrl: "img/kzeng.jpg",
    // websiteUrl:
    //   "https://www.astate.edu/college/conhp/departments/medical-imaging-radiation-sciences/faculty-staff/people-details.dot?pid=c3c780d3-4217-4fb8-8de7-ad82894aed6b",
  },
  {
    name: "Xinyuan Zhang",
    role: "Project Coach",
    title: "Predoctoral Fellow",
    affiliation: "Cancer Prevention and Research Institute of Texas",
    teams: ["Business Analytics Demand Forecasting"],
    imageUrl: "img/xzhang.webp",
    // websiteUrl:
    //   "https://www.astate.edu/college/conhp/departments/medical-imaging-radiation-sciences/faculty-staff/people-details.dot?pid=c3c780d3-4217-4fb8-8de7-ad82894aed6b",
  },
];

const onSiteMentors = [
  {
    name: "Karl Walker",
    title: "Chair, Assoc. Prof.",
    institution: "Univ. of Arkansas at Pine Bluff",
    expertise: "Bioinf., AI, database",
  },
  {
    name: "Danny Czejdo",
    title: "Prof. of Science",
    institution: "Fayetteville State University",
    expertise: "knowledge sys., CS edu.",
  },
  {
    name: "Samar Swaid",
    title: "Chair, Assoc. Prof.",
    institution: "Philander Smith College",
    expertise: "CS, biol. science",
  },
  {
    name: "Timothy Oladunni",
    title: "Asst. Prof.",
    institution: "Univ. of the District of Columbia",
    expertise: "AI, ML, Deep Learning",
  },
  {
    name: "Letu Qingge",
    title: "Asst. Prof.",
    institution: "North Carolina A&T State Univ.",
    expertise: "Bioinf., comp. biol., data sci.",
  },
  {
    name: "Sarah Buxbaum",
    title: "Assoc. Prof.",
    institution: "Florida A&M University",
    expertise: "Data sci., biostats, pub. health",
  },
  {
    name: "Babu Baniya",
    title: "Asst. Prof.",
    institution: "Grambling State University",
    expertise: "ML, Bioinf., Big Data",
  },
  {
    name: "Denise Ferebee",
    title: "Asst. Prof., Director",
    institution: "LeMoyne-Owen College",
    expertise: "CS, Cybersecurity",
  },
  {
    name: "Noushin Ghaffari",
    title: "Asst. Prof.",
    institution: "Prairie View A&M University",
    expertise: "Bioinformatics, AI",
  },
  {
    name: "Sridhar Malkaram",
    title: "Assoc. Prof.",
    institution: "West Virginia State University",
    expertise: "Bioinformatics",
  },
  {
    name: "Michelle Foster",
    title: "Chair, Coordinator",
    institution: "Alabama State University",
    expertise: "Applied math, CS",
  },
  {
    name: "Michael Blanchett",
    title: "Program Director",
    institution: "Shorter College",
    expertise: "STEM education",
  },
  {
    name: "Ayishih Bellew",
    title: "Professor",
    institution: "University of the Virgin Islands",
    expertise: "Data science, analytics, CS",
  },
  {
    name: "Zephyrinus C. Okonkwo",
    title: "Professor",
    institution: "Albany State University",
    expertise: "CS edu., math, Cryptography",
  },
  {
    name: "Suzan Anwar",
    title: "Asst. Prof.",
    institution: "Philander Smith College",
    expertise: "CS, Computer Vision, AI",
  },
  {
    name: "Hongmei Chi",
    title: "Professor",
    institution: "Florida A&M University",
    expertise: "Data sci., Cybersec., Bioinf.",
  },
  {
    name: "Linwei Niu",
    title: "Asst. Prof",
    institution: "Howard University",
    expertise: "Embedded Systems, Sustainable Comp",
  },
  {
    name: "Lei Huang",
    title: "Assoc. Prof.",
    institution: "Prairie View A&M University",
    expertise: "Cloud Computing, Big Data, HPC",
  },
  {
    name: "Demetrius Gilbert",
    title: "Assoc. Dean",
    institution: "Shorter College",
    expertise: "Education, Development",
  },
  {
    name: "Marc Boumedine",
    title: "Assoc. Prof.",
    institution: "University of the Virgin Islands",
    expertise: "Fuzzy Logic, Data Mining",
  },
  {
    name: "Joanne Luciano",
    title: "Professor",
    institution: "University of the Virgin Islands",
    expertise: "Bioinf., Knowledge repr. Data Sci",
  },
  {
    name: "Robert Owor",
    title: "Professor",
    institution: "Albany State University",
    expertise: "AI, HCI, Cybersec",
  },
  {
    name: "Ping Zhang",
    title: "Chair, Professor",
    institution: "Alcorn State University",
    expertise: "Computer Vision, AI/ML",
  },
  {
    name: "Raphael Isokpehi",
    title: "Professor",
    institution: "Jackson State University",
    expertise: "Bioinf., NLP,",
  },
  {
    name: "Sanjay Batra",
    title: "Assoc. Professor",
    institution: "Southern University-Baton Rouge",
    expertise: "Biochem, Molecular Biology",
  },
  {
    name: "Nikunja Swain",
    title: "Chair, Professor",
    institution: "South Carolina State University",
    expertise: "Cybersecurity, CS edu.",
  },
  {
    name: "Naima Naheed",
    title: "Assoc. Professor",
    institution: "Benedict College",
    expertise: "Digital Image Processing, Cybersec, Applied Math",
  },
  {
    name: "Mian Hossain",
    title: "Professor",
    institution: "Morgan State University",
    expertise: "Health & Policy, Statistics",
  },
];

import { getImgUrl } from "../helper";

export default {
  methods: {
    getImgUrl,
  },
  setup() {
    return {
      people,
      onSiteMentors,
    };
  },

  metaInfo: {
    title: "About Us",
  },
};
</script>
